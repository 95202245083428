/* reset */
body {
  overflow-x: hidden;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
textarea,
p,
blockquote,
th,
td,
input,
select,
button {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

fieldset,
img {
  border: 0 none;
}

dl,
ul,
ol,
menu,
li {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote::before,
blockquote::after,
q::before,
q::after {
  content: "";
  content: none;
}

input,
select,
textarea,
button {
  vertical-align: middle;
}

input::-ms-clear {
  display: none;
}

button {
  border: 0 none;
  background-color: transparent;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

body {
  background: #fff;
}

a {
  color: #333;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}

a:active {
  background-color: transparent;
}

address,
caption,
cite,
code,
dfn,
em,
var {
  font-style: normal;
  font-weight: normal;
}

body,
th,
td,
input,
select,
textarea,
button {
  font-size: 14px;
  line-height: 1.5;
  font-family: "Montserrat", Inter, NotoSansKR, "Noto Sans KR", -apple-system,
    BlinkMacSystemFont, Roboto, sans-serif;
  color: #000;
  font-weight: 400;
  letter-spacing: -0.3px;
}
